<style lang="less">
  @theme-1-color: #F4628F;
  @theme-4-color: #005AFF;
  .login-page {
    .fm-input-new-prefix-prepend .iconfont {
      margin-left: 10px;
      color: @theme-1-color;
    }
    .fm-form-item .fm-form-item-label {
      display: none;
    }
    .fm-form-item .fm-input-new {
      border-radius: 20px;
      border-color: @theme-1-color;
      width: 100%;
      background-color: #FFF;
      &.fm-input-focus {
        box-shadow: 0 0 0px 3px rgba(244, 98, 143, .15);
      }
      input:-internal-autofill-selected {
        border-radius: 20px;
        background-color: #FFF !important;
        background-image: none !important;
        color: rgb(0, 0, 0) !important;
        box-shadow: inset 0 0 0 1000px #FFF !important;
      }
      input:-webkit-autofill {
        border-radius: 20px;
        background-color: #FFF !important;
        background-image: none !important;
        box-shadow: 0 0 0px 1000px #FFF inset !important;
      } 
      input:-webkit-autofill:focus {
        border-radius: 20px;
        background-color: #FFF !important;
        background-image: none !important;
        box-shadow: 0 0 0px 1000px #FFF inset !important;
      } 
    }
  }
  .login-page.page-theme-4 {
    .fm-input-new-prefix-prepend .iconfont {
      color: @theme-4-color;
    }
    .fm-form-item .fm-input-new {
      border-color: transparent;
      background-color: #FFF;
      &.fm-input-focus {
        box-shadow: 0 0 0px 3px rgba(0, 90, 255, .2);
      }
    }
    .verifier-message {
      color: @theme-4-color;
    }
  }
  .login-page {
    &.page-theme-0, &.page-theme-1, &.page-theme-2, &.page-theme-3 {
      .fm-btn:hover {
        border-color: @theme-1-color;
        color: #FFF;
        background-color: @theme-1-color;
      }
    }
  }
  .bottom-text {
    line-height: 1.5;
    color: #000;
  }
  .login-page.page-theme-5 {
    .login-form {
      border-radius: 20px;
      background-image: url('/static/images/login/5-1.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center center;
      transform: translate(-50%, -50%);
      left: 50%;
      .login-btn {
        background: #6C573C;
        border-color: #6C573C;
        &:hover {
          color: #FFF;
        }
      }
      .fm-input-new-prefix-prepend .iconfont, .cmp-name, .link, .bottom-text {
        color: #6C573C;
      }
      .fm-form-item .fm-input-new {
        border-color: #6C573C;
        &.fm-input-focus {
          box-shadow: 0 0 0px 3px rgba(108, 87, 60, .2);
        }
      }
    }
  }
</style>

<style lang="less" scoped>
  @theme-1-color: #F4628F;
  @theme-4-color: #005AFF;
  .login-page {
    position: relative;
    height: 100vh;
    width: 100vw;
  }
  .login-page-logout {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    color: #999;
  }
  .login-form-wap {
    left: 0 !important;
  }
  .login-form {
    z-index: 1;
    transition: all .3s;
    transition-timing-function: ease;
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    background-color: #FFF;
    border-radius: 40px;
    padding: 40px 30px;
    will-change: transform, left, backdrop-filter, background-color;
    width: 400px;
    .cmp-name {
      color: #F4628F;
      font-size: 28px;
      text-align: center;
    }
  }
  .title {
    color: #23322B;
    padding: 10px 0;
    border-bottom: 2px solid #23322B;
    display: inline-block;
    margin: 20px 0;
  }
  .login-btn {
    font-size: 14px;
    color: #FFF;
    border-color: @theme-1-color;
    background: @theme-1-color;
    border-radius: 40px;
  }
  .link {
    color: @theme-1-color;
  }
  .page-theme-0 {
    .login-form {
      left: calc(100% - 89px);
      transform: translateY(-50%) translateX(-100%);
    }
  }
  .page-theme-1, .page-theme-2 {
    .login-form {
      left: 89px;
    }
  }
  .page-theme-3 {
    .login-form {
      background-image: url('/static/images/login/3-0.png');
      background-size: 400px 400px;
      padding-left: 442px;
      box-sizing: content-box;
      background-repeat: no-repeat;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
    }
  }
  .page-theme-4 {
    background-image: url('/static/images/login/4.png');
    .login-form {
      // background-color: rgba(255,255,255,.4);
      background-color: transparent;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      .cmp-name {
        color: @theme-4-color;
      }
      .title {
        color: @theme-4-color;
        border-bottom-color: @theme-4-color;
      }
      .login-btn {
        border-color: transparent;
        color: @theme-4-color;
        background: #FFF;
        &:hover {
          color: @theme-4-color;
          background-color: rgba(0, 90, 255, .2);
        }
      }
    }
    .link {
      color: @theme-4-color;
    }
  }
  .backgrounds {
    z-index: -1;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    transition: all .3s;
    transition-timing-function: ease;
    transform: translateY(0%);
    will-change: transform;
    .img {
      width: 100vw;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }
  }
  .themes-toggle {
    position: fixed;
    right: 10px;
    top: 10px;
    display: flex;
    z-index: 2;
    .theme {
      margin-left: 10px;
      width: 35px;
      height: 16px;
      border-radius: 5px;
      cursor: pointer;
      border: 1px solid #EEE;
      background-size: 100% 100%;
      &.active {
        border-color: rgb(254, 163, 7);
      }
    }
  }
  @media screen and (max-width: 600px) {
    body {
      .login-form {
        padding: 40px 20px;
        width: 90vw;
      }
      .title {
        margin: 10px 0;
      }
    }
  }
</style>

<template>
  <div class="login-page" v-if="!isLogout" :class="{['page-theme-' + themeType]: true}" @keyup.enter="login">
    <div class="themes-toggle">
      <div class="theme" :class="{'active': defaultThemeType === i}" @click="setTheme(i)" :key="i" v-for="i in themes" :style="{backgroundImage: 'url(/static/images/login/' + i + '.png)'}"></div>
    </div>
    <div class="backgrounds" :style="{transform: 'translateY(-' + (themeTypeIndex * 100) + '%)'}">
      <div class="img" :key="i" v-for="i in themes" :style="{backgroundImage: 'url(/static/images/login/' + i + '.png)'}"></div>
    </div>
    <div class="login-form" :class="{'login-form-wap': clientType === 'wap'}">
      <h1 class="cmp-name">{{cmpName || '一远科技有限公司'}}</h1>
      <div class="title">帐号密码登录</div>
      <fm-form ref="verifier-form">
        <fm-form-item>
          <fm-input-new v-model="account" v-verifier required clearable placeholder="请输入帐号">
            <i class="iconfont icon-yonghu2" slot="prefix"></i>
          </fm-input-new>
        </fm-form-item>
        <fm-form-item>
          <fm-input-new v-model="password" v-verifier required clearable type="password" placeholder="请输入密码">
            <i class="iconfont icon-mima-old" slot="prefix"></i>
          </fm-input-new>
        </fm-form-item>
        <fm-form-item>
          <div style="display: flex; justify-content: space-between;">
            <div></div>
            <!-- <fm-checkbox v-model="remember" label="自动登录"></fm-checkbox> -->
            <a class="link" href="javascript:;" @click="wjmm">忘记密码</a>
          </div>
        </fm-form-item>
        <fm-form-item>
          <fm-btn class="login-btn" @click="login" :v-loadingx="loading" size="large" block>登录</fm-btn>
        </fm-form-item>
        <fm-form-item>
          <div class="bottom-text" style="display: block; text-align: center;">安徽一远智能科技有限责任公司</div>
          <a target="_blank" class="bottom-text" style="display: block; text-align: center;" href="https://beian.miit.gov.cn">{{ipc ? '备案号：' + ipc : ''}}</a>
        </fm-form-item>
      </fm-form>
    </div>
    <change-pwd :show="showChangePass" @changeOver="changeOver" :token="token" @close="showChangePass = false"></change-pwd>
  </div>
  <div class="login-page login-page-logout" v-else>
    注销中...
  </div>
</template>

<script>
import ChangePwd from './changePwd'
import {
  localstorageHelper
} from '@/fmlib/index'
import sysEnv from '@/env/env'

import {
  authRequest
} from '@/api'

import {
  rsaHelper
} from '@/fmlib'

import { v4 as uuidv4 } from 'uuid'

let md5 = require('js-md5')

let defaultThemeType = sysEnv.defaultTheme || localStorage.getItem('defaultThemeType')
let themes = sysEnv.themes || [0, 1, 2, 3, 4, 5]

export default {
  components: {
    ChangePwd
  },
  data () {
    return {
      ipc: sysEnv.ipc || '',
      themeType: this.clientType === 'wap' ? 1 : (defaultThemeType ? parseInt(defaultThemeType) : ~~(Math.random() * 10 % themes.length)),
      defaultThemeType: parseInt(defaultThemeType),
      account: '',
      password: '',
      isDefaultPwd: false,
      defaultPwd: 'e10adc3949ba59abbe56e057f20f883e',
      remember: false,
      loading: false,
      isLogout: false,
      showChangePass: false,
      token: ''
    }
  },
  created () {
    this.isLogout = this.$router.history.current.params.logOut === 1
  },
  computed: {
    cmpName () {
      return sysEnv.cmpName
    },
    themes () {
      return themes
    },
    clientType () {
      return this.$store.getters.clientType
    },
    themeTypeIndex () {
      return themes.findIndex(v => v === this.themeType)
    }
  },
  methods: {
    changeOver () {
      this.password = ''
      this.$notice.success({
        title: '系统提示',
        desc: '密码修改完成，请使用新密码重新登陆!'
      })
    },
    setTheme (type) {
      this.themeType = type
      if (this.defaultThemeType === type) {
        this.defaultThemeType = null
        localStorage.removeItem('defaultThemeType')
      } else {
        this.defaultThemeType = type
        localStorage.setItem('defaultThemeType', type)
      }
    },
    wjmm () {
      this.$notice.info({
        title: '系统提示',
        desc: '请联系管理员重置密码!'
      })
    },
    async login () {
      try {
        this.loading = true
        if (!this.$refs['verifier-form'].verifier()) {
          throw new Error('请正确填写表单')
        }
        let uuid = uuidv4()
        let pubKey = await authRequest.getLoginPubKey({'rsaKey': uuid})
        this.isDefaultPwd = this.defaultPwd === md5(this.password)
        const result = await authRequest.login({
          account: this.account,
          password: rsaHelper.rsaEncrypt(pubKey.rsaPubKey, this.password),
          clientType: this.$store.getters.clientType === 'wap' ? 'mobile' : this.$store.getters.clientType,
          rsaKey: uuid
        })
        this.loading = false
        if (this.isDefaultPwd) {
          this.token = result.token
          this.$notice.warning({
            title: '系统提示',
            desc: '密码为初始密码，请修改后重新登录!'
          })
          this.showChangePass = true
          return
        } else {
          localstorageHelper.updateData('token', result.token)
          this.$store.dispatch('authInit')
        }
      } catch (error) {
        this.loading = false
        // this.$notice.warning(error.message)
      }
    }
  }
}
</script>